// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-forgot-password-js": () => import("./../../../src/pages/forgot-password.js" /* webpackChunkName: "component---src-pages-forgot-password-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-set-password-js": () => import("./../../../src/pages/set-password.js" /* webpackChunkName: "component---src-pages-set-password-js" */),
  "component---src-pages-validate-js": () => import("./../../../src/pages/validate.js" /* webpackChunkName: "component---src-pages-validate-js" */)
}

